<template>
  <div>
    <div class="container text-center">
      <div class="mb-3">
        <h2>
          {{ master_name }}
        </h2>
      </div>
      <div class="mb-5">
        <h2>
          Внесите время по проекту
        </h2>
      </div>
      <div class="row align-items-center justify-content-center">
        <nav class="nav">
          <router-link to="/" class="nav-link">Мои проекты</router-link>
          <router-link to="/all" class="nav-link active">Все проекты</router-link>
        </nav>
        <div class="data-input">
          <v-date-picker v-model="date" mode="date" color="red" :masks="{ input: ['DD.MM.YYYY']}">
            <template v-slot="{ inputValue, inputEvents }">
              Дата: <input
                  class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                  :value="inputValue"
                  v-on="inputEvents"

              />
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-xl-12" style="display: flex">
          <div class="input-group input-group-lg input-search ">
            <input type="text" placeholder="Введите название проекта" class="form-control rounded-0"
                   aria-label="Large" aria-describedby="inputGroup-sizing-sm" @input="find" v-model="search_sting">
          </div>
          <div class="fa-close col-sm-2 clear-search" @click="clear_find">
            <a href="#">
              <font-awesome-icon :icon="clearSearch" class="fas"/>
            </a>
          </div>
        </div>
      </div>

      <div class="row align-items-center" v-for="(project,index) in projects" :key="project.id + 'project'">
        <div class="col-sm-3">
                    <span>
                      {{ project.name }}
                    </span>
        </div>

        <div class="col-sm-3">
          <div class="dropdown">
            <button class="btn btn-default btn-lg btn-block dropdown-toggle border rounded-0"
                    type="button" :id="'dropdownMenu'+index" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"
                    v-bind:class="{disabled: project.send, 'border-danger': project.noTime, 'border-dark': !project.noTime }">
              {{ times_pick["id" + project.id] }}
            </button>
            <div class="dropdown-menu rounded-0" :aria-labelledby="'dropdownMenu'+index">
              <button v-for="time in times" class="dropdown-item text-center" type="button" :key="time.id + project.id"
                      @click="chooseTime(time.id,project.id)" v-bind:class="{disabled: project.send}">
                {{ time.time }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="input-group input-group-lg ">
            <input type="text" placeholder="Комментарий" class="form-control rounded-0" aria-label="Large"
                   aria-describedby="inputGroup-sizing-sm" v-model="project.comment" :disabled="project.send === true"
                   v-bind:class="{'border-danger': project.stage}">
          </div>
        </div>
        <div class="col-sm-2">
          <button class="btn btn-default btn-lg btn-block bg-warning text-black rounded"
                  v-bind:class="{disabled: project.send}" @click="save_project(project.id)">OK
          </button>
        </div>
      </div>
    </div>
    <modal name="modal-send" :width="300"
           :height="75"
           :adaptive="true">
      <div style="padding: 25px">Данные отправлены</div>
    </modal>
  </div>
</template>

<script>
import {faArrowCircleLeft, faWindowClose} from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'Home',
  data() {
    return {
      date: new Date(),
      vue: "true",
      projects: [],
      projects_finds: [],
      times_pick: [],
      times: [
        {
          id: 1,
          time: '0.5 часа',
        },
        {
          id: 2,
          time: '1 час'
        },
        {
          id: 3,
          time: '1.5 часа'
        },
        {
          id: 4,
          time: '2 часа'
        },
        {
          id: 5,
          time: '2.5 часа'
        },
        {
          id: 6,
          time: '3 часа'
        },
        {
          id: 7,
          time: '3.5 часа'
        },
        {
          id: 8,
          time: '4 часа'
        },
        {
          id: 9,
          time: '4.5 часа'
        },
        {
          id:10,
          time: '5 часов'
        },
        {
          id: 11,
          time: '5.5 часов'
        },
        {
          id: 12,
          time: '6 часов'
        },
        {
          id: 13,
          time: '6.5 часов'
        },
        {
          id: 14,
          time: '7 часов'
        },
        {
          id: 15,
          time: '7.5 часов'
        },
        {
          id: 16,
          time: '8 часов'
        }, {
          id: 17,
          time: '8.5 часов'
        },
        {
          id: 18,
          time: '9 часов'
        },
        {
          id: 19,
          time: '9.5 часов'
        },
        {
          id: 20,
          time: '10 часов'
        },
        {
          id: 21,
          time: '10.5 часов'
        },
        {
          id: 22,
          time: '11 часов'
        },
        {
          id: 23,
          time: '11.5 часов'
        },
        {
          id: 24,
          time: '12 часов'
        },
      ],
      //curTime: "Время",
      curTimeID: 0,
      search_sting: "",
      master_name: localStorage.name
    }
  },
  computed: {
    appExit() {
      return faArrowCircleLeft
    },
    clearSearch() {
      return faWindowClose
    }
  },
  methods: {
    show() {
      this.$modal.show('modal-send');
    },
    hide() {
      this.$modal.hide('modal-send');
    },
    clear_find() {
      this.search_sting = ''
      this.projects = this.projects_finds
    },
    name() {
      console.log(this.vue)
    },
    chooseTime(id, projectID) {
      let curID = this.times.filter(time => time.id === id)[0]
      this.$set(this.times_pick, "id" + projectID, curID.time)
      //this.curTime = this.curTimeID.time
    },
    load_project(projects) {
      console.log('projects',projects.result.length)
      // eslint-disable-next-line no-unused-vars
      let stage_id = false

      projects.result.forEach(project => {
        stage_id = project.STAGE_ID === "C2:9";
        this.projects.push({
          id: project.ID,
          name: project.TITLE,
          comment: "",
          send: false,
          noTime: false,
          stageID: stage_id,
          stage: false
        })
        this.$set(this.times_pick, "id" + project.ID, "Выберите время")
      })
      this.projects_finds = this.projects
    },
    find() {
      console.log(this.projects_finds)
      if (this.search_sting.length < 3) return;
      let arrProj = [];

      let search = this.search_sting.toLowerCase()
      this.projects_finds.forEach(proj => {
        let name = proj.name.toLowerCase()
        if (name.search(search) !== -1) {
          arrProj.push(proj)
          console.log('finds');
        }
      })
      //this.projects = arrProj
      console.log(arrProj)
      this.$set(this, "projects", arrProj)
    },
    async save_project(id) {
      console.log(id)

      let proj = this.projects.filter(proj => proj.id === id)[0]
      console.log(proj)
      if (id === "296" && proj.comment.length === 0) {
        proj.stage = true
        return;
      }

      if (proj.send) return;
      let time = (+this.times_pick['id' + proj.id].replace(/[^\d.-]/g, ''))
      console.log('time', time)
      if (time === 0) {
        proj.noTime = true
        return;
      } else {
        proj.noTime = false
      }

      let fields = {
        id: +proj.id,
      }
      let get_deal_master = await fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(fields)
      });
      let deal_master = await get_deal_master.json();
      console.log(deal_master)
      let id_deal_product = null
      let project_number = deal_master.result.UF_CRM_1603358687729
      let id_deal_main = null
      let title_deal_main = null
      console.log('deal_main', deal_master.result.UF_CRM_1587418255)
      console.log('project_number', deal_master.result.UF_CRM_1603358687729)

      if (deal_master.result.UF_CRM_1587418255) {
        id_deal_product = +deal_master.result.UF_CRM_1587418255;
        let fields = {
          id: id_deal_product
        };
        let get_deal_main = await fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.get', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(fields)
        })
        let deal_main = await get_deal_main.json()
        if (deal_main.result.UF_CRM_1587418255) {
          id_deal_main = deal_main.result.UF_CRM_1587418255
          title_deal_main = deal_main.result.TITLE
        } else {
          console.log('ID материнской сделки отсутвует', deal_master.result.UF_CRM_1587418255)
          title_deal_main = deal_master.result.TITLE
        }
      } else {
        console.log('ID изделия сделки отсутвует', deal_master.result.UF_CRM_1587418255)
        title_deal_main = deal_master.result.TITLE
      }

      let fields_add_list = {
        'IBLOCK_TYPE_ID': 'lists',
        'IBLOCK_ID': 22,
        'ELEMENT_CODE': id_deal_main + (+new Date()),
        'FIELDS': {
          'NAME': title_deal_main,
          'PROPERTY_138': this.date.toLocaleDateString('ru'),//Дата
          'PROPERTY_139': localStorage.id,//Мастер
          'PROPERTY_140': time,//Время
          'PROPERTY_141': localStorage.rate,//Ставка, руб.
          'PROPERTY_142': +localStorage.rate * time,//Сумма, руб.
          'PROPERTY_143': project_number,//№ заказа
          'PROPERTY_144': id_deal_product,//Проект
          'PROPERTY_145': proj.id,//Изделие
          'PROPERTY_146': proj.comment,//Комментарий
        }
      }
      let add_list_query = await fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/lists.element.add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(fields_add_list)
      })
      let list_query = await add_list_query.json()
      proj.send = true
      proj.noTime = false
      proj.stage = false
      console.log(list_query)
      this.show()
      setTimeout(() => {
        this.hide()
      }, 1500)
    }
  },
  mounted() {
    this.projects.forEach(proj => {
      this.$set(this.times_pick, "id" + proj.id, "0.5 часа")
    })
  },
  created() {
    let fields = {
      order: {
        ID: "ASC"
      },
      filter: {
        CATEGORY_ID: 1,
        STAGE_SEMANTIC_ID: "P"
        //UF_CRM_1601541194: localStorage.id
      },
      select: ["ID", "TITLE", "STAGE_SEMANTIC_ID", "STAGE_ID"]
    };
    fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(fields)
    })
        .then(response => response.json())
        .then(projects_get => {
          console.log('projects.total', projects_get.total)
          if (projects_get.total > 50) {
            let total = projects_get.total
            let start = 50
            // eslint-disable-next-line no-unused-vars
            let dataListFilter = {}
            this.load_project(projects_get)
            while (start < total) {
              let prod = {
                result:[]
              }
              dataListFilter = {
                order: {
                  ID: "ASC"
                },
                filter: {
                  CATEGORY_ID: 1,
                  STAGE_SEMANTIC_ID: "P"
                },
                start: start,
                select: ["ID", "TITLE","STAGE_SEMANTIC_ID","STAGE_ID"]
              }
              fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.list', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(dataListFilter)
              })
                  .then(response => response.json())
                  .then(projects_more => {
                    projects_more.result.forEach(project_add => {
                      //console.log('ID ', project_add.ID,project_add.TITLE)
                      prod.result.push({
                        ID: project_add.ID,
                        TITLE: project_add.TITLE,
                        STAGE_ID: project_add.STAGE_ID,
                        STAGE_SEMANTIC_ID: project_add.STAGE_SEMANTIC_ID,
                      })
                    })
                    this.load_project(prod)
                  })
              //$getDeals['next'] = $getDeals_add['next'];
              start = start + 50
            }
            console.log('prod',projects_get)
            let prod2 = {
              result:[]
            }
            let dataMore = {
              order: {
                ID: "ASC"
              },
              filter: {
                ID: [299,298,297,296]
              },
              select: ["ID", "TITLE","STAGE_SEMANTIC_ID","STAGE_ID"]
            }
            fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.list', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8'
              },
              body: JSON.stringify(dataMore)
            })
                .then(response => response.json())
                .then(projects_add => {
                  projects_add.result.forEach(project_add2 => {
                    //console.log('ID ', project_add.ID,project_add.TITLE)
                    prod2.result.push({
                      ID: project_add2.ID,
                      TITLE: project_add2.TITLE,
                      STAGE_ID: project_add2.STAGE_ID,
                      STAGE_SEMANTIC_ID: project_add2.STAGE_SEMANTIC_ID,
                    })
                  })
                  this.load_project(prod2)
                })
            //this.load_project(projects)
          } else {
            this.load_project(projects_get)
          }

        });
  }
}

</script>

<style>

.data-input {
  text-align-last: center;
}

.fas {
  position: absolute;
  transform: scale(-1, 1);
  font-size: 30px;
  vertical-align: sub;
  margin-right: 50px;
  color: #ffc105;
}

.header .exit {
  margin-left: 40px;
  color: #ffc105;
  font-size: 20px;
}

.row {
  margin-bottom: 5%;
}

.dropdown-menu {
  position: absolute;
  width: 50%;
  left: 50px;
  top: 100px;
  z-index: 3;
  transform: translate(0, -50%);
  background: #fff !important;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

}

.col-sm {
  margin-bottom: 10%;
}

.fa-close {
  position: relative;
  top: -27px;
  left: -27px;
  cursor: pointer;
}

.fa-window-close {
  font-size: 57px;
}

.nav {
  width: 960px;

  margin: 0 auto;
}

.nav a {
  display: table;
  margin: 0 auto 30px;
  font-size: 20px;
}

.nav a:hover {
  color: #ffc105;
}

.nav-link {
  color: black;
}

.active {
  color: #ffc105;
}

.input-group input:focus {
  border-radius: 0;
}

.input-group input {
  border-radius: 0;
  border: 1px solid black;
}

.clear-search {
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .input-search {
    width: 285%;
  }

  .clear-search {
    top: -3px;
    left: 0;
  }
}

</style>
